@use '../../scss/abstracts/' as *;

.topnav {
	@include flex(center, right);
	// margin-bottom: $spacing-tb;

	@include tablet {
		margin-bottom: 0;
	}
}

.sidebar-toggle {
	--size: 50px;
	width: var(--size);
	height: var(--size);
	@include flex(center, center);
	cursor: pointer;
	display: none;

	@include tablet {
		display: flex;
	}

	i {
		font-size: 2rem;
		// color: $mainColor;
	}
}
